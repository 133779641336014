/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonInput from 'components/ArgonInput';
import TooltipWrapper from 'components/TooltipWrapper';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputAdornment } from '@mui/material';

function FormikTextInput({
  id,
  name,
  label,
  tooltip,
  required,
  textColor,
  loading,
  formik,
  success,
  helperTextColor,
  ...rest
}) {
  return (
    <>
      {(label || tooltip) && (
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <TooltipWrapper tooltip={tooltip}>
            <ArgonTypography
              // component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color={textColor}
              {...rest}>
              {label}&nbsp;
              {required && (
                // <sup>
                <span style={{ color: 'red' }}>*</span>
                // </sup>
              )}
              {tooltip && (
                <ArgonBox ml={1} display="inline-block">
                  <ArgonTypography variant="regular">
                    <InfoOutlinedIcon color="secondary" />
                  </ArgonTypography>
                </ArgonBox>
              )}
            </ArgonTypography>
          </TooltipWrapper>
        </ArgonBox>
      )}
      <ArgonInput
        id={id}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        success={success || (formik.values[name] && !formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        helperTextColor={helperTextColor}
        loading={loading}
        endAdornment={
          required && !label && !formik.values[name] ? (
            <InputAdornment position="end">
              <ErrorOutlineIcon sx={{ color: 'error.main' }} />
            </InputAdornment>
          ) : undefined
        }
        {...rest}
      />
    </>
  );
}

// typechecking props for FormField
FormikTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
  textColor: PropTypes.string,
  helperTextColor: PropTypes.string,
  formik: PropTypes.object.isRequired
};

export default FormikTextInput;
