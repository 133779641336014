/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { Alert, Switch, Table, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/TableCell';

import { useArgonController } from 'context';
import DeleteIcon from '@mui/icons-material/Delete';
import EventLogo from './components/EventLogo';
// import CreateEventInstances from './components/CreateEventInstances';
import SelectOrganization from 'Routes/Organizer/Components/SelectOrganization';
import FormikTextInput from 'components/FormikTextInput';
import FormikEventSlugInput from 'components/FormikEventSlugInput';

function BasicInfo({
  formik,
  onDeleteEvent,
  onSaveEvent,
  onUpdateEventLogoUrl,
  dense, // displays a desed version of the form
  noGutter,
  onCreateNewEventInstance,
  enableLogoUpload = true,
  showBottomButtonRow = true,
  addNewEvent = false
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const [isDeleting, setIsDeleting] = useState(false);
  const [shouldSyncSlug, setShouldSyncSlug] = useState(true);

  function makeUrlSafe(str) {
    // First, handle special German characters
    const germanChars = {
      ä: 'ae',
      ö: 'oe',
      ü: 'ue',
      ß: 'ss',
      Ä: 'Ae',
      Ö: 'Oe',
      Ü: 'Ue'
    };

    // Replace German characters first
    let result = str;
    for (const [char, replacement] of Object.entries(germanChars)) {
      result = result.replace(new RegExp(char, 'g'), replacement);
    }

    // Then replace all remaining non-URL safe characters with hyphen
    result = result
      // Replace any character that's not alphanumeric, hyphen, or underscore
      .replace(/[^a-zA-Z0-9-_]/g, '-')
      // Replace multiple consecutive hyphens with a single hyphen
      .replace(/-+/g, '-');

    return result;
  }

  const handleNameChange = (e) => {
    formik.handleChange(e);
    // Nur den Slug ändern wenn er leer ist UND shouldSyncSlug true ist
    if (shouldSyncSlug && (!formik.values.slug || formik.values.slug === '')) {
      const sanitizedSlug = makeUrlSafe(e.target.value);
      formik.setFieldValue('slug', sanitizedSlug);
    }
  };

  const handleNameBlur = (e) => {
    formik.handleBlur(e);
    setShouldSyncSlug(false);
  };

  const handleSwitchChanged = async (event) => {
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  const handleLogoUploaded = (url) => {
    formik.setFieldValue('pic_logo_url', url);
    onUpdateEventLogoUrl && onUpdateEventLogoUrl(url);
  };

  const handleSaveEventButton = () => {
    onSaveEvent && onSaveEvent(formik.values);
  };

  const handleDeleteEventButton = async () => {
    setIsDeleting(true);
    try {
      await onDeleteEvent();
    } catch (error) {
      console.error('Error deleting event:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (!formik.values) return <div>Loading...</div>;

  return (
    <>
      <Card id="basic-info" sx={{ overflow: 'visible' }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h6">Event Setup</ArgonTypography>
        </ArgonBox>
        <ArgonBox pb={3} px={3}>
          <Grid container spacing={3}>
            {!dense && (
              <>
                <Grid container item spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={enableLogoUpload ? 8 : 12}
                    style={{
                      paddingTop: '12px',
                      alignItems: 'flex-start'
                    }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%">
                          {/* <ArgonBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize">
                          Organization
                        </ArgonTypography>
                      </ArgonBox> */}
                          <SelectOrganization
                            id="organizationId"
                            name="organizationId"
                            title="Select your organization"
                            initialOrganizationId={formik.values.organizationId}
                            required
                            size="regular"
                            disabled={!addNewEvent}
                            showLoader
                            onChange={(orga) =>
                              handleSelectMenuChanged(
                                'organizationId',
                                orga.value
                              )
                            }
                          />
                        </ArgonBox>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%">
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block">
                            <ArgonTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize">
                              sports <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonSelect
                            id="sports"
                            name="sports"
                            placeholder="Please choose"
                            options={selectData.sportsType}
                            value={selectData.sportsType.filter(
                              (option) => option.value === formik.values.sports
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('sports', option.value)
                            }
                            error={
                              formik.touched.sports &&
                              Boolean(formik.errors.sports)
                            }
                            helperText={
                              formik.touched.sports && formik.errors.sports
                            }
                          />
                        </ArgonBox>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%">
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block">
                            <ArgonTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize">
                              event type <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonSelect
                            id="type"
                            name="type"
                            placeholder="Please choose"
                            options={selectData.eventType}
                            value={selectData.eventType.filter(
                              (option) => option.value === formik.values.type
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('type', option.value)
                            }
                            error={
                              formik.touched.type && Boolean(formik.errors.type)
                            }
                            helperText={
                              formik.touched.type && formik.errors.type
                            }
                          />
                        </ArgonBox>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        {/* <FormField
                      id="name"
                      name="name"
                      label="Event Title"
                      placeholder="Your event name"
                      required
                      value={formik.values.name}
                      onChange={handleTextfieldChanged}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    /> */}
                        <FormikTextInput
                          id="name"
                          name="name"
                          required
                          label="Event Title"
                          placeholder="Your event name"
                          formik={formik}
                          // onChange={formik.handleChange}
                          onChange={handleNameChange}
                          onBlur={handleNameBlur}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {enableLogoUpload && (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        // paddingTop: '12px',
                        alignItems: 'flex-start'
                      }}>
                      <EventLogo
                        eventData={formik.values}
                        onLogoUploaded={handleLogoUploaded}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormikTextInput
                    id="descr_short"
                    name="descr_short"
                    label="Subtitle"
                    placeholder="A short text to show as subtitle"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormikEventSlugInput
                    id="slug"
                    name="slug"
                    label="Slug (shortcut for event URL)"
                    tooltip=" This will be used to create an easy to remember event URL. If you plan to create this event on a yearly basis, you should add a year behind the name."
                    placeholder="A slug for a short link"
                    formik={formik}
                    eventId={formik.values._id}
                    onChange={() => setShouldSyncSlug(false)}
                  />
                  {formik.values.slug && (
                    <ArgonBox ml={0.5} mt={-1}>
                      <ArgonTypography variant="caption" color="secondary">
                        Link:{' '}
                        {`https://www.strong.one/${formik.values.slug} || strong.one/${formik.values.slug}`}
                      </ArgonTypography>
                    </ArgonBox>
                  )}
                </Grid>
              </>
            )}
            {dense && (
              <>
                <Grid item xs={12} sm={12}>
                  <Table sx={{ minWidth: '36rem' }}>
                    {/* <ArgonBox component="thead">
                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      Search parameters (only used for finding events on the
                      website)
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      N/Y
                    </TableCell>
                  </TableRow>
                </ArgonBox> */}
                    <TableBody>
                      <TableRow>
                        <TableCell padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Select Organization{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Select the organization you want to create the
                              event for
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                          <SelectOrganization
                            id="organizationId"
                            name="organizationId"
                            initialOrganizationId={formik.values.organizationId}
                            disabled={!addNewEvent}
                            // title="Select your organization"
                            // required
                            size="regular"
                            showLoader
                            onChange={(orga) =>
                              handleSelectMenuChanged(
                                'organizationId',
                                orga.value
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              variant="caption"
                              fontWeight="bold">
                              Sports <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Choose the sport for your event
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                          <ArgonSelect
                            id="sports"
                            name="sports"
                            placeholder="Please choose"
                            options={selectData.sportsType}
                            value={selectData.sportsType.filter(
                              (option) => option.value === formik.values.sports
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('sports', option.value)
                            }
                            error={
                              formik.touched.sports &&
                              Boolean(formik.errors.sports)
                            }
                            helperText={
                              formik.touched.sports && formik.errors.sports
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              // variant="button"
                              // fontWeight="regular"
                              variant="caption"
                              fontWeight="bold">
                              Event type <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Select the type of event
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                          <ArgonSelect
                            id="type"
                            name="type"
                            placeholder="Please choose"
                            overflow
                            options={selectData.eventType}
                            value={selectData.eventType.filter(
                              (option) => option.value === formik.values.type
                            )}
                            onChange={(option) =>
                              handleSelectMenuChanged('type', option.value)
                            }
                            error={
                              formik.touched.type && Boolean(formik.errors.type)
                            }
                            helperText={
                              formik.touched.type && formik.errors.type
                            }
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              // variant="button"
                              // fontWeight="regular"
                              variant="caption"
                              fontWeight="bold">
                              Event name <span style={{ color: 'red' }}>*</span>
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Choose a compelling title for your event
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                          <FormikTextInput
                            id="name"
                            name="name"
                            required
                            // label="Event Title"
                            placeholder="Your event name"
                            formik={formik}
                            onChange={handleNameChange}
                            onBlur={handleNameBlur}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell padding={[1, 1, 1, 0.5]}>
                          <ArgonBox lineHeight={1.4}>
                            <ArgonTypography
                              display="block"
                              // variant="button"
                              // fontWeight="regular"
                              variant="caption"
                              fontWeight="bold">
                              Subtitle
                            </ArgonTypography>
                            <ArgonTypography
                              variant="caption"
                              color="text"
                              fontWeight="regular">
                              Add a short text to show as subtitle (optional,
                              but recommended)
                            </ArgonTypography>
                          </ArgonBox>
                        </TableCell>
                        <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                          <FormikTextInput
                            id="descr_short"
                            name="descr_short"
                            // label="Subtitle"
                            // placeholder="A short text to show as subtitle"
                            formik={formik}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12}>
              <Table sx={{ minWidth: '36rem' }}>
                <ArgonBox component="thead">
                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      Search parameters (only used for finding events on the
                      website)
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      N/Y
                    </TableCell>
                  </TableRow>
                </ArgonBox>
                <TableBody>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For individuals
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for individuals
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forIndividuals"
                        name="forIndividuals"
                        value
                        onChange={handleSwitchChanged}
                        checked={formik.values.forIndividuals}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For teams
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for teams
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forTeams"
                        name="forTeams"
                        onChange={handleSwitchChanged}
                        checked={formik.values.forTeams}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          // variant="button"
                          // fontWeight="regular"
                          variant="caption"
                          fontWeight="bold">
                          For kids
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for kids
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forUnderage"
                        name="forUnderage"
                        onChange={handleSwitchChanged}
                        checked={formik.values.forUnderage}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            {showBottomButtonRow && (
              <Grid item xs={12} sm={12}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonButton
                    id="btnSubmit"
                    variant="gradient"
                    color="info"
                    size="small"
                    loading={formik.isSubmitting}
                    onClick={handleSaveEventButton}>
                    {formik.values._id === undefined ||
                    formik.values._id === null ||
                    formik.values._id === ''
                      ? 'Next Step'
                      : 'Save Event'}
                  </ArgonButton>
                  <ArgonButton
                    id="btnDelete"
                    variant="text"
                    color="error"
                    onClick={handleDeleteEventButton}
                    loading={isDeleting}>
                    <DeleteIcon />
                    &nbsp;Delete
                    {/* <Icon>delete</Icon>&nbsp;Delete */}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            )}
          </Grid>
        </ArgonBox>
      </Card>
      {/* 
      <CreateEventInstances
        onCreateNewEventInstance={onCreateNewEventInstance}
      /> */}
    </>
  );
}

export default BasicInfo;
