import React, { useEffect, useState } from 'react';

import { Card, Typography } from '@mui/material';
import ArgonBox from 'components/ArgonBox';
import BaseLayout from './BaseLayout';
import EventCard from './EventCard';

import { useLazyQuery, gql, useQuery } from '@apollo/client';
import { EVENTS_GET_ALL } from 'GraphQL/Events/queries';
import { Box } from '@mui/system';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import ArgonButton from 'components/ArgonButton';
import { useNavigate } from 'react-router-dom';
import { getSession } from 'services/cognito/cognitoAccount';
import ArgonTypography from 'components/ArgonTypography';
import Loader from 'Loader';
import LoaderWrap from 'LoaderWrap';
import { motion } from 'framer-motion';
import { getAllEventInstancesForAdminDashboard } from 'services/REST_API/event-instances';
import EventInstanceCard from './EventInstanceCard';
import ArgonSelect from 'components/ArgonSelect';
import ArgonInput from 'components/ArgonInput';
import FormField from 'components/FormField';

const EventList = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [organizationId, setOrganizationId] = useState(null);
  // const [allowedEvents, setAllowedEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const displayOptionsTimeframe = [
    { label: 'Upcoming', value: 'upcoming' },
    { label: 'Past', value: 'past' },
    { label: 'All', value: 'all' }
  ];

  const displayOptionsEventInstances = [
    // { label: 'Events', value: 'events' },
    { label: 'Event Instances', value: 'eventInstances' }
  ];

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    displayOptionsTimeframe[0]
  );
  const [selectedEventDisplayOptions, setSelectedEventDisplayOptions] =
    useState(displayOptionsEventInstances[0]);

  const handleDisplayOptionsTimeFrameChanged = (option) => {
    setSelectedTimeFrame(option);

    if (option.value === 'upcoming') {
      // filter eventInstancesList to only show upcoming (startDate today or in the future)

      const upcomingEventInstances = eventInstancesList.filter(
        (eventInstance) => new Date(eventInstance.startDate) >= new Date()
      );
      const groupedUpcomingEventInstances = groupEventInstancesByYear(
        upcomingEventInstances
      );
      console.log(
        'Grouped upcoming event instances:',
        groupedUpcomingEventInstances
      );
      setEventInstancesListGroupedByYear(groupedUpcomingEventInstances);
    } else if (option.value === 'past') {
      // filter eventInstancesList to only show past (startDate in the past)
      console.log('Filtering past event instances.');
      const pastEventInstances = eventInstancesList.filter(
        (eventInstance) => new Date(eventInstance.startDate) < new Date()
      );
      const groupedPastEventInstances =
        groupEventInstancesByYear(pastEventInstances);
      console.log('Grouped past event instances:', groupedPastEventInstances);
      setEventInstancesListGroupedByYear(groupedPastEventInstances);
    } else if (option.value === 'all') {
      console.log('Showing all event instances.');
      setEventInstancesListGroupedByYear(
        groupEventInstancesByYear(eventInstancesList)
      );
    }
  };

  const handleDisplayOptionsEventInstancesChanged = (option) => {
    setSelectedEventDisplayOptions(option);
  };
  // const [fetchEvents] = useLazyQuery(EVENTS_GET_ALL, {
  //   onCompleted: (data) => {
  //     console.log('Events fetched:', data);
  //   }
  // });

  const [eventInstancesList, setEventInstancesList] = useState([]);
  const [eventInstancesListGroupedByYear, setEventInstancesListGroupedByYear] =
    useState([]);

  // const [fetchEvents, { loading: eventsLoading, error, data }] = useLazyQuery(
  //   EVENTS_GET_ALL,
  //   {
  //     onCompleted: (data) => {
  //       console.log('Events fetched:', data);
  //     }
  //   }
  // );

  const groupEventInstancesByYear = (eventInstances) => {
    return Object.entries(
      eventInstances.reduce((acc, eventInstance) => {
        const year = new Date(eventInstance.startDate).getFullYear();
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(eventInstance);
        // now sort descending by startDate
        acc[year].sort((a, b) => {
          return new Date(b.startDate) - new Date(a.startDate);
        });
        return acc;
      }, {})
    )
      .sort(([yearA], [yearB]) => yearB - yearA)
      .reduce((sortedObj, [year, events]) => {
        sortedObj[year] = events;
        return sortedObj;
      }, {});
  };

  const filterEventInstances = (instances, query) => {
    if (!query) return instances;
    return instances?.filter(
      (instance) =>
        instance?.name?.toLowerCase().includes(query?.toLowerCase()) ||
        instance?.event?.name?.toLowerCase().includes(query?.toLowerCase()) ||
        instance?.type?.toLowerCase().includes(query?.toLowerCase()) ||
        instance?.description?.toLowerCase().includes(query?.toLowerCase()) ||
        instance?.event?.sports?.toLowerCase().includes(query?.toLowerCase()) ||
        instance?.event?.type?.toLowerCase().includes(query?.toLowerCase())
    );
  };

  const filteredEventInstances = filterEventInstances(
    eventInstancesList,
    searchQuery
  );

  const groupedFilteredEventInstances = groupEventInstancesByYear(
    filteredEventInstances
  );

  useEffect(() => {
    setLoading(true);
    getSession()
      .then(async (session) => {
        const groups = session.idToken.payload['cognito:groups'];
        if (!groups) {
          navigate('/login');
        }
        const eventAdminGroups = groups.filter((group) =>
          group.startsWith('event-admin')
        );

        const associatedEvents = eventAdminGroups.map(
          (group) => group.split('-')[2]
        );
        console.log('Associated events:', associatedEvents);

        // now get Events from database
        // setAllowedEvents(associatedEvents);
        // fetchEvents({
        //   variables: {
        //     data: {
        //       _id_in: associatedEvents
        //     }
        //   }
        // });

        getAllEventInstancesForAdminDashboard()
          .then((data) => {
            console.log('Event instances fetched:', data);
            console.log(
              'Grouped event instances:',
              groupEventInstancesByYear(data)
            );
            setEventInstancesList(data);
            setEventInstancesListGroupedByYear(groupEventInstancesByYear(data));
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching event instances:', error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error fetching Auth token:', error);
        // TODO: if not logged in, forward to login page
      });
  }, []);

  // if (error) {
  //   console.error(error);
  //   Swal.fire({
  //     title: 'Error!',
  //     text: 'Something went wrong. Please try again.',
  //     icon: 'error',
  //     confirmButtonText: 'OK'
  //   });
  // }

  // const Loader = () => {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       minHeight="100vh">
  //       <PuffLoader color="#36d7b7" />
  //     </Box>
  //   );
  // };

  // if (eventInstancesList.length > 0) {
  return (
    <div>
      <BaseLayout navbarTitle="Events">
        <ArgonBox
          mt={2}
          mb={4}
          display="flex"
          justifyContent="space-between"
          alignItems="end">
          <ArgonBox display="flex" gap={2}>
            <ArgonBox>
              <ArgonSelect
                id="timeframe-select"
                name="timeframe-select"
                size="small"
                label="Timeframe"
                options={displayOptionsTimeframe}
                value={selectedTimeFrame}
                onChange={(option) => {
                  console.log('Selected timeframe:', option);
                  handleDisplayOptionsTimeFrameChanged(option);
                }}
              />
            </ArgonBox>
            <ArgonBox>
              <ArgonSelect
                id="eventInstances-select"
                name="eventInstances-select"
                size="small"
                label="Show Events / Instances"
                options={displayOptionsEventInstances}
                value={selectedEventDisplayOptions}
                onChange={(option) => {
                  console.log('Selected event instances:', option);
                  handleDisplayOptionsEventInstancesChanged(option);
                }}
              />
            </ArgonBox>
            <ArgonBox>
              <FormField
                id="search-input"
                name="search-input"
                size="small"
                label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </ArgonBox>
          </ArgonBox>
          <ArgonBox>
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              disabled={loading}
              onClick={() => navigate('/organizer/events/create')}>
              Add New Event
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>

        <LoaderWrap loading={loading}>
          <ArgonBox mt={0} mb={2} key={searchQuery}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}>
              {/* {data.events.map((event, index) => (
                  <motion.div
                    key={event._id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}>
                    <ArgonBox mb={0} mt={0}>
                      <EventCard if={event._id} event={event} />
                    </ArgonBox>
                  </motion.div>
                ))} */}

              {Object.entries(groupedFilteredEventInstances)
                .sort(([yearA], [yearB]) => yearB - yearA)
                .map(([year, eventInstances], index) => (
                  <motion.div
                    key={year}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.5,
                      delay:
                        index === 0
                          ? 0
                          : Object.entries(groupedFilteredEventInstances)
                              .slice(0, index)
                              .reduce(
                                (acc, [_, instances]) => acc + instances.length,
                                0
                              ) * 0.2
                    }}>
                    <ArgonBox mb={2} mt={2}>
                      <ArgonTypography
                        variant="h4"
                        fontWeight="medium"
                        color="secondary">
                        {year === 'NaN' ? 'Year not set' : year}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox mb={0} mt={0}>
                      {eventInstances.map((eventInstance, eventIndex) => (
                        <motion.div
                          key={eventInstance._id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 0.5,
                            delay:
                              index === 0
                                ? eventIndex * 0.2
                                : Object.entries(groupedFilteredEventInstances)
                                    .slice(0, index)
                                    .reduce(
                                      (acc, [_, instances]) =>
                                        acc + instances.length,
                                      0
                                    ) *
                                    0.2 +
                                  eventIndex * 0.2 +
                                  0.2
                          }}>
                          <EventInstanceCard eventInstance={eventInstance} />
                        </motion.div>
                      ))}
                    </ArgonBox>
                  </motion.div>
                ))}
            </motion.div>

            {!loading && filteredEventInstances.length === 0 && (
              <ArgonBox mt={4} mb={4} pl={2}>
                <ArgonTypography variant="regular" color="dark">
                  No events found. Try adjusting your search or use the button
                  to create a new event.
                </ArgonTypography>
              </ArgonBox>
            )}
          </ArgonBox>
        </LoaderWrap>
      </BaseLayout>
    </div>
  );
};

export default EventList;
