import axios from 'axios';
import { getSession, refreshIdToken } from '../../cognito/cognitoAccount';

/**
 * Fetches all event instances for the admin dashboard.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} [params.organizationId] - The ID of the organization to fetch event instances for. Optional.
 * @returns {Promise<Object>} A promise that resolves with the event instances data.
 * @throws {Error} If there's an error fetching the data.
 */
export const getAllEventInstancesForAdminDashboard = ({
  organizationId
} = {}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event-instances/list`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ organizationId })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });

export const checkIfEventInstanceSlugExists = ({
  slug,
  excludeEventInstanceId
} = {}) =>
  new Promise(async (resolve, reject) => {
    const session = await getSession();

    var config = {
      method: 'post',

      url: `${process.env.REACT_APP_BACKEND_URI}/v1/event-instances/check-slug`,

      headers: {
        Authorization: `Bearer ${session.idToken.jwtToken}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ slug, excludeEventInstanceId })
    };

    axios(config)
      .then(function (response) {
        console.log('data from axios request', response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error(error);
        reject(error);
      });
  });
