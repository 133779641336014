import { EVENT_FRAGMENT } from './fragments';

const { gql } = require('@apollo/client');

export const EVENTS_GET_ALL = gql`
  ${EVENT_FRAGMENT}
  query Events($data: EventQueryInput) {
    events(query: $data) {
      ...EventFragment
    }
  }
`;

export const EVENT_GET_BY_ID = gql`
  ${EVENT_FRAGMENT}
  query getEventById($eventId: ObjectId!) {
    event(query: { _id: $eventId }) {
      ...EventFragment
    }
  }
`;

export const EVENT_GET_BY_SLUG = gql`
  ${EVENT_FRAGMENT}
  query getEventBySlug($slug: String!) {
    event(query: { slug: $slug }) {
      ...EventFragment
    }
  }
`;
