import { InputAdornment } from '@mui/material';
import Loader from 'Loader';
import ArgonBox from 'components/ArgonBox';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTypography from 'components/ArgonTypography';
import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { getCurrentUsersOrganizations } from 'services/cognito/userOrganizations';

export default function SelectOrganization({
  title,
  required,
  showLoader,
  size = 'regular',
  onChange,
  initialOrganizationId,
  enableAllOrganizations,
  disabled,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({});

  useEffect(() => {
    setLoading(true);
    // get all organizations the user is a member of
    getCurrentUsersOrganizations()
      .then((res) => {
        console.log('User organizations: ', res);

        let temp = res.map((org) => ({
          label: org?.organizationName,
          value: org?.organizationId
        }));

        // if allowed, add option to select all organizations
        if (enableAllOrganizations)
          temp.unshift({ label: 'All organizations', value: null });

        console.log('temp: ', temp);
        setOrganizations(temp);

        if (initialOrganizationId) {
          const initialOrganization = temp.find(
            (org) => org.value === initialOrganizationId.toString()
          );
          console.log(
            'SelectOrganization: initialOrganization found: ',
            initialOrganization
          );

          setSelectedOrganization(initialOrganization);
          onChange && onChange(initialOrganization);
        } else {
          setSelectedOrganization(temp[0]);
          onChange && onChange(temp[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   setSelectedOrganization(value);
  // }, [value]);

  const handleSetSelectedOrganization = (organization) => {
    console.log('Selected organization: ', organization);
    setSelectedOrganization(organization);
    onChange && onChange(organization);
  };

  // if (showLoader && loading) return <Loader />;

  return (
    <>
      {/* {organizations.length > 0 && ( */}
      <ArgonBox mb={0}>
        {title && (
          <ArgonBox mb={0} ml={0.5}>
            <ArgonTypography variant="caption" fontWeight="bold">
              {/* {title || 'Select the organization you want to manage:'} */}
              {title}
              {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
            </ArgonTypography>
          </ArgonBox>
        )}
        <ArgonSelect
          id="organization-select"
          name="organization-select"
          size={size}
          placeHolder="Select Organization"
          options={organizations}
          onChange={handleSetSelectedOrganization}
          value={selectedOrganization}
          overFlow
          isLoading={loading}
          isDisabled={disabled}
          {...rest}
          success={selectedOrganization?.value !== null}
        />
      </ArgonBox>
      {/* )} */}
    </>
  );
}
