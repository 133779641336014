import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, InputAdornment } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { checkIfEventSlugExists } from 'services/REST_API/events';
import debouncePromise from 'debounce-promise';
import ArgonInput from 'components/ArgonInput';
import ArgonBox from 'components/ArgonBox';
import TooltipWrapper from 'components/TooltipWrapper';
import ArgonTypography from 'components/ArgonTypography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Create a debounced version of the API call
const debouncedCheckSlug = debouncePromise(
  async ({ slug, excludedEventId }) => {
    if (!slug || slug.length < 6) return null;
    return checkIfEventSlugExists({ slug, excludedEventId });
  },
  1500
);

function FormikEventSlugInput({
  id,
  name,
  eventId,
  formik,
  label,
  onChange,
  required,
  tooltip,
  textColor,
  ...rest
}) {
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [error, setError] = useState(null);

  console.log('FormikEventSlugInput: eventId', eventId);

  function makeUrlSafe(str) {
    // First, handle special German characters
    const germanChars = {
      ä: 'ae',
      ö: 'oe',
      ü: 'ue',
      ß: 'ss',
      Ä: 'ae',
      Ö: 'oe',
      Ü: 'ue'
    };

    // Replace German characters first
    let result = str.toLowerCase();
    for (const [char, replacement] of Object.entries(germanChars)) {
      result = result.replace(new RegExp(char, 'g'), replacement);
    }

    // Then replace all remaining non-URL safe characters with hyphen
    result = result
      // Replace any character that's not alphanumeric, hyphen, or underscore
      .replace(/[^a-z0-9-_]/g, '-')
      // Replace multiple consecutive hyphens with a single hyphen
      .replace(/-+/g, '-');
    // Remove leading and trailing hyphens
    // .replace(/^-+|-+$/g, '');

    return result;
  }

  // // Convert special characters to URL-safe format
  // const sanitizeSlug = (value) => {
  //   // Convert to lowercase first
  //   value = value.toLowerCase();

  //   // Convert German umlauts to their double-letter equivalents
  //   value = value
  //     .replace(/ä/g, 'ae')
  //     .replace(/ö/g, 'oe')
  //     .replace(/ü/g, 'ue')
  //     .replace(/ß/g, 'ss');

  //   // Convert all non-URL safe characters to hyphens
  //   value = value.replace(/[^a-z0-9]+/g, '-');

  //   // Replace multiple consecutive hyphens with a single hyphen
  //   value = value.replace(/-+/g, '-');

  //   // Remove leading and trailing hyphens
  //   value = value.replace(/^-+|-+$/g, '');

  //   return value;
  // };

  // Handle input changes
  // Watch for external changes to the formik value
  useEffect(() => {
    const currentValue = formik.values[name];
    if (currentValue) {
      const sanitizedValue = makeUrlSafe(currentValue);
      if (sanitizedValue !== currentValue) {
        formik.setFieldValue(name, sanitizedValue, false);
      }

      // Always validate when value changes and meets minimum length
      if (sanitizedValue && sanitizedValue.length >= 6) {
        setIsChecking(true);
        checkSlug(sanitizedValue);
      } else {
        setIsChecking(false);
        setIsAvailable(null);
        setError(null);
      }
    }
  }, [formik.values[name], name, formik.setFieldValue]);

  const handleChange = (e) => {
    // const value = sanitizeSlug(e.target.value);
    const value = makeUrlSafe(e.target.value);
    console.log('original typed slug:', e.target.value);
    console.log('sanitized slug:', value);

    // Update the input field value directly
    e.target.value = value;

    // Create a new event with the sanitized value
    const newEvent = { ...e, target: { ...e.target, value } };

    // Call the original onChange if provided
    if (rest.onChange) {
      rest.onChange(newEvent);
    }

    // Update formik value
    formik.setFieldValue(name, value);

    // Only start validation when minimum length is reached
    if (value && value.length >= 6) {
      setIsChecking(true);
      checkSlug(value);
    } else {
      setIsChecking(false);
      setIsAvailable(null);
      setError(null);
    }
  };

  const checkSlug = async (slug) => {
    if (!slug || slug.length < 6) return;

    try {
      const result = await debouncedCheckSlug({
        slug,
        excludedEventId: eventId
      });
      if (result) {
        setIsAvailable(result.exists === false);
        setError(null);
      }
    } catch (err) {
      console.error('Error checking slug:', err);
      setError('Error checking slug availability');
      setIsAvailable(null);
    } finally {
      setIsChecking(false);
    }
  };

  const getEndAdornment = () => {
    if (isChecking) {
      return (
        <InputAdornment position="end">
          <CircularProgress size={20} />
        </InputAdornment>
      );
    }

    // if (error) {
    //   return (
    //     <InputAdornment position="end">
    //       <ErrorOutlineIcon sx={{ color: 'error.main' }} />
    //     </InputAdornment>
    //   );
    // }

    // if (isAvailable === true) {
    //   return (
    //     <InputAdornment position="end">
    //       <CheckCircleIcon sx={{ color: 'success.main' }} />
    //     </InputAdornment>
    //   );
    // }

    // if (isAvailable === false) {
    //   return (
    //     <InputAdornment position="end">
    //       <ErrorOutlineIcon sx={{ color: 'error.main' }} />
    //     </InputAdornment>
    //   );
    // }

    return null;
  };

  return (
    <>
      {(label || tooltip) && (
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <TooltipWrapper tooltip={tooltip}>
            <ArgonTypography
              // component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color={textColor}>
              {label}&nbsp;
              {required && (
                // <sup>
                <span style={{ color: 'red' }}>*</span>
                // </sup>
              )}
              {tooltip && (
                <ArgonBox ml={1} display="inline-block">
                  <ArgonTypography variant="regular">
                    <InfoOutlinedIcon color="secondary" />
                  </ArgonTypography>
                </ArgonBox>
              )}
            </ArgonTypography>
          </TooltipWrapper>
        </ArgonBox>
      )}
      <ArgonInput
        id={id}
        name={name}
        value={formik.values[name]}
        {...rest}
        inputProps={{
          spellCheck: false,
          autoCorrect: 'off',
          autoCapitalize: 'off'
        }}
        error={
          !isChecking && formik.values.slug && formik.values.slug.length < 6
            ? 'Slug must be at least 6 characters long'
            : isAvailable === false
            ? 'Slug already exists'
            : null
        }
        helperText={
          formik.values.slug && formik.values.slug.length < 6
            ? 'Slug must be at least 6 characters long'
            : isAvailable === false
            ? 'Slug already exists'
            : isChecking
            ? 'Checking if slug is available...'
            : isAvailable !== true
            ? 'Only lowercase letters, numbers, and hyphens are allowed'
            : null
        }
        helperTextColor={
          (formik.values.slug && formik.values.slug.length < 6) ||
          isAvailable === false
            ? undefined // error color
            : 'secondary' // hint color
        }
        success={!isChecking && isAvailable === true}
        endAdornment={getEndAdornment()}
        onChange={handleChange}
      />
    </>

    // <FormikTextInput
    //   {...rest}
    //   key={formik.values[name]}
    //   formik={formik}
    //   inputProps={{
    //     spellCheck: false,
    //     autoCorrect: 'off',
    //     autoCapitalize: 'off'
    //   }}
    //   error={
    //     formik.values.slug && formik.values.slug.length < 6
    //       ? 'Slug must be at least 6 characters long'
    //       : isAvailable === false
    //       ? 'Slug already exists'
    //       : null
    //   }
    //   helperText={
    //     formik.values.slug && formik.values.slug.length < 6
    //       ? 'Slug must be at least 6 characters long'
    //       : isAvailable === false
    //       ? 'Slug already exists'
    //       : isChecking
    //       ? 'Checking if slug is available...'
    //       : isAvailable !== true
    //       ? 'Only lowercase letters, numbers, and hyphens are allowed'
    //       : null
    //   }
    //   helperTextColor={
    //     (formik.values.slug && formik.values.slug.length < 6) ||
    //     isAvailable === false
    //       ? undefined // error color
    //       : 'secondary' // hint color
    //   }
    //   success={!isChecking && isAvailable === true}
    //   endAdornment={getEndAdornment()}
    //   onChange={handleChange}
    // />
  );
}

FormikEventSlugInput.propTypes = {
  eventId: PropTypes.string,
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default FormikEventSlugInput;
